var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chats-list" },
    [
      !_vm.simple
        ? _c("en-title-card", { attrs: { name: "沟通" } }, [
            _c(
              "div",
              {
                staticClass: "right-slot",
                attrs: { slot: "right" },
                slot: "right",
              },
              [
                _c("en-search", {
                  staticClass: "search-bar",
                  on: { search: _vm.searchChatList },
                  model: {
                    value: _vm.searchKey,
                    callback: function ($$v) {
                      _vm.searchKey = $$v
                    },
                    expression: "searchKey",
                  },
                }),
                _c("el-divider", {
                  staticClass: "line",
                  attrs: { direction: "vertical" },
                }),
                _c(
                  "div",
                  { staticClass: "add-chat", on: { click: _vm.openAddChat } },
                  [
                    _c("en-icon", {
                      attrs: { name: "faqiduihua", size: "small" },
                    }),
                    _c("div", { staticClass: "label" }, [_vm._v(" 发起会话 ")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.chatList.length
        ? _c("en-result", {
            class: { "index-size": _vm.simple },
            attrs: { type: "NoData" },
          })
        : _vm._l(_vm.chatList, function (item, index) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "left-chat-item",
                class: {
                  active: _vm.session.id === item.id && !_vm.simple,
                  "un-read": item.unread,
                },
                on: {
                  click: function ($event) {
                    return _vm.tabClick(item)
                  },
                },
              },
              [
                item.unread > 0 && !_vm.isMuteTeam(item)
                  ? _c(
                      "el-badge",
                      {
                        staticClass: "item",
                        attrs: { value: item.unread, max: 99 },
                      },
                      [
                        item.scene !== "team"
                          ? _c("en-user-logo", {
                              attrs: {
                                "user-name": item.name ? item.name : "",
                                size: "40",
                                "image-url": _vm.userLogo(item),
                                icon: "renyuan-wodehangcheng",
                              },
                            })
                          : _c("group-avatar", {
                              attrs: { teamId: item.to, size: 40 },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                item.unread === 0 || !item.unread || _vm.isMuteTeam(item)
                  ? _c(
                      "div",
                      [
                        item.scene !== "team"
                          ? _c("en-user-logo", {
                              class: { "red-star": item.unread > 0 },
                              attrs: {
                                "user-name": item.name ? item.name : "",
                                "image-url": _vm.userLogo(item),
                                size: "40",
                              },
                            })
                          : _c("group-avatar", {
                              attrs: { teamId: item.to, size: 40 },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "user-info" }, [
                  _c("div", { staticClass: "user-info-head" }, [
                    _c("div", { staticClass: "user-name" }, [
                      _vm._v(" " + _vm._s(item.name) + " "),
                    ]),
                    _c("div", { staticClass: "last-time" }, [
                      _vm._v(" " + _vm._s(item.showDate) + " "),
                    ]),
                  ]),
                  _c("div", { staticClass: "message" }, [
                    _vm._v(" " + _vm._s(item.text) + " "),
                  ]),
                ]),
                _c("en-icon", {
                  staticClass: "delete-chats-icon",
                  attrs: { name: "shibai", size: "20px" },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleDeleteSession(item, index)
                    },
                  },
                }),
                _vm.isMuteTeam(item)
                  ? _c("en-icon", {
                      staticClass: "mute-team-icon",
                      attrs: { name: "miandarao", size: "small" },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
      _c("add-chats", {
        ref: "addPerson",
        attrs: { "create-team": "" },
        on: { change: _vm.handleConfirmSelectPerson },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }