var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "group-avatar-wrap",
      style: { width: _vm.size + "px", height: _vm.size + "px" },
    },
    _vm._l(_vm.serverCustom.groupImageList, function (item, ind) {
      return _c("en-user-logo", {
        key: ind,
        class: _vm.computedAvatar,
        attrs: {
          "user-name": item.name,
          size: "20",
          "image-url": _vm.userLogo(item),
          icon: !item.imageUrl && !item.name ? "renyuan-wodehangcheng" : "",
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }