<!--
 * @Author: wuqi
 * @Date: 2021-08-13 15:31:08
 * @LastEditors: wuqi
 * @LastEditTime: 2021-08-31 11:42:56
 * @Description:
-->
<template>
  <div class='group-avatar-wrap' :style="{width:size+'px',height:size+'px'}">
    <en-user-logo
      :class='computedAvatar'
      v-for="(item,ind) in serverCustom.groupImageList"
      :user-name="item.name"
      :key="ind"
      size="20" :image-url="userLogo(item)"
      :icon='!item.imageUrl&&!item.name?"renyuan-wodehangcheng":""'></en-user-logo>
  </div>
</template>

<script>
import { getFileUrl } from "@/tools/getFileUrl";

export default {
  name: "groupAvatar",
  data() {
    return {
    };
  },
  props: {
    avatars: {
      type: Array,
      default() {
        return [];
      }
    },
    teamId: [Number, String],
    size: {
      type: Number,
      default: 40
    }
  },
  computed: {
    computedAvatar() {
      if (this.serverCustom?.groupImageList.length > 4) {
        return "avatarItem--3";
      }
      if (this.serverCustom?.groupImageList.length > 1) {
        return "avatarItem--2";
      }
      return "avatarItem--1";
    },
    serverCustom() {
      const curTeamInfo = this.$store.state.chat.teamlist.find((team) => team.teamId === this.teamId);
      const serveCustom = curTeamInfo?.serverCustom ? JSON.parse(curTeamInfo.serverCustom) : { groupImageList: [] };
      if (serveCustom?.groupImageList.length > 4) {
        serveCustom?.groupImageList.splice(4);
      }
      return serveCustom;
    }

  },
  created() {
  },
  mounted() {

  },
  methods: {
    userLogo(item) {
      if (item.imageUrl && item.imageUrl.indexOf("img.api") > 0) {
        return item.imageUrl;
      } if (item.imageUrl) {
        return getFileUrl(item.imageUrl, "000");
      }
      return "";
    }
  }
};
</script>

<style lang='scss' scoped>
.group-avatar-wrap{
     overflow: hidden;
     display: flex;
     justify-content: center;
     align-items: center;
     align-content: center;
     flex-wrap: wrap-reverse;
     border: #fff 1px solid;
     border-radius: 100%;
    .avatarItem--1 {
        width: 98%!important;
        height: 98%!important;
    }

    .avatarItem--2 {
        width: 47%!important;
        height: 47%!important;
        margin: 1%;

    }

    .avatarItem--3 {
        width: 32%!important;
        height: 30%!important;
        margin: 1%;
    }

}
</style>
