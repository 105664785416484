<template>
  <div class="chats-list">
    <en-title-card name="沟通" v-if="!simple">
      <div slot="right" class="right-slot">
        <en-search
          class='search-bar'
          v-model="searchKey"
          @search='searchChatList'
        >
        </en-search>
        <el-divider class='line' direction="vertical"></el-divider>
        <div class="add-chat" @click="openAddChat">
          <en-icon name="faqiduihua" size="small">
          </en-icon>
          <div class="label">
            发起会话
          </div>
        </div>
      </div>
    </en-title-card>
    <en-result type="NoData" v-if="!chatList.length" :class="{'index-size':simple}"></en-result>
    <div
      v-else
      v-for="(item,index) in chatList"
      :key="item.id"
      class="left-chat-item"
      :class="{'active':session.id === item.id&&!simple,'un-read':item.unread}"
      @click="tabClick(item)"
    >
      <el-badge v-if="item.unread > 0&&!isMuteTeam(item)" :value="item.unread" :max="99" class="item">
        <en-user-logo v-if="item.scene !== 'team'" :user-name="item.name ? item.name : ''" size="40" :image-url="userLogo(item)" icon='renyuan-wodehangcheng'>
        </en-user-logo>
        <group-avatar v-else :teamId="item.to" :size='40'></group-avatar>
      </el-badge>
      <div v-if="item.unread === 0 || !item.unread||isMuteTeam(item)">
        <en-user-logo
          v-if="item.scene !== 'team'"
          :class="{'red-star':item.unread > 0}"
          :user-name="item.name ? item.name : ''"
          :image-url="userLogo(item)" size="40" ></en-user-logo>
          <group-avatar v-else :teamId="item.to" :size='40'></group-avatar>
      </div>

      <div class="user-info">
        <div class="user-info-head">
          <div class="user-name">
            {{ item.name }}
          </div>
          <div class="last-time">
            {{ item.showDate }}
          </div>
        </div>
        <div class="message">
          {{ item.text }}
        </div>
      </div>
      <en-icon
        name="shibai"
        class="delete-chats-icon"
        size="20px"
        @click.native.stop="handleDeleteSession(item,index)"
      >
      </en-icon>
      <en-icon class='mute-team-icon' v-if="isMuteTeam(item)"  name='miandarao' size="small"> </en-icon>
    </div>
    <add-chats ref="addPerson" create-team   @change="handleConfirmSelectPerson">
    </add-chats>
  </div>
</template>

<script>
// import Utils from "@/tools/chat";
import { mapState } from "vuex";
import { cloneDeep } from "lodash";
import { getFileUrl } from "@/tools/getFileUrl";
import addChats from "./addChats";
import GroupAvatar from "./groupAvatar";

export default {
  name: "ChatList",
  props: {
    simple: { // 简介模式 例如：（首页）
      type: Boolean,
      default: false
    }
  },
  components: { addChats, GroupAvatar },
  data() {
    return {
      chatList: [],
      searchKey: "",
      showaddChats: false,
      loadingConfig: {
        type: "NoData",
        subTitle: "数据加载中..."
      }
    };
  },

  activated() {
    if (this.$route.query.id) { // 从首页过来 需要重新设置当前会话
      this.handleChangeSession(this.$route.query);
    } else if (!this.simple) {
      this.handleChangeSession({ id: this.currSessionId, unread: 1 });
    }
  },
  computed: {
    nim() { // NIM配置信息
      return window.nim;
    },
    session() {
      return this.sessionMap[this.currSessionId] || {};
    },
    ...mapState("chat", ["sessionlist", "sessionMap", "currSessionId", "muteTeamIds"])
  },
  mounted() {

  },
  methods: {
    // 搜索会话
    searchChatList() {
       this.chatList = this.sessionlist.filter((session) => (session.name?.includes(this.searchKey) || session.text.includes(this.searchKey)));
    },
    openAddChat() {
      this.showaddChats = true;// 解决穿梭框隐藏后内置dragger组件无法重新渲染的问题 // TODO en-transfer-data需要优化 1:在隐藏的时候提供可以清空内部状态的钩子
      this.$nextTick(() => {
        this.$refs.addPerson.open();
      });
    },
    isMuteTeam(item) {
      return item.scene === "team" && this.muteTeamIds.includes(item.to);
    },
    userLogo(item) {
      if (item.avatar && item.avatar.indexOf("img.api") > 0) {
        return item.avatar;
      } if (item.avatar) {
        const avatar = item.avatar.split("://")[1].split("?")[0];
        return getFileUrl(avatar, "000");
      }
      return "";
    },
    tabClick(session) {
      console.log(session);
      if (!this.simple) {
        this.handleChangeSession(session);
      } else {
        this.$router.push({
          path: "/chat",
          query: { id: session.id, unread: session.unread }
        });
      }
    },
    search() {
      console.log("serach");
    },
    change() {
      console.log("change");
    },
    // 过滤
    filterSession() {

    },
    /**
     * @description 切换聊天对象
     * @param itemData
     */
    handleChangeSession(session) {
      // 重置会话的未读数
      if (session.unread > 0) {
        this.$nextTick(() => {
          window.nim && window.nim.resetSessionUnread(session.id);
        });
      }
      this.$store.dispatch("chat/setCurrSession", session.id);
    },
    /**
     * @description 检测是否需要插入数据
     */
    checkNeedInsertMsg(msgInfo) {
      // 截取聊天对象的ID，进行相应的业务判断
      const msgId = msgInfo.scene === "p2p" ? msgInfo.id.split("p2p-")[1] : msgInfo.id;
      // 判断消息来源是否为当前会话，并且非当前用户所发送的
      if (msgId === this.session.dataId && msgInfo.lastMsg.from !== this.userInfo.name) {
        this.$store.commit("chat/_MxChangeVuexState", { _newMsg: msgInfo });
      }
    },
    /**
     * @description 删除会话
     * @param session 会话
     */
    handleDeleteSession(session) {
      this.$store.dispatch("chat/deleteSession", session.id || (`${session.scene}-${session.to}`));
      // this.$confirm("确定要删除此会话？", "操作警告", { type: "warning" }).then((res) => {
      //   if (res) {
      //   }
      // });
    },
    /**
     * @description 确认选择
     */
    handleConfirmSelectPerson(res, newSession) {
      this.selectedPersons = res;
      newSession ? this.handleChangeSession({ id: newSession }) : "";
      this.showaddChats = false;
    }
  },
  watch: {
    sessionlist: {
      async handler(list) {
        const sessions = cloneDeep(list);
        this.chatList = sessions.filter((session) => (session.name?.includes(this.searchKey) || session.text.includes(this.searchKey)));
      },
      immediate: true
    }
  }

};
</script>

<style lang="scss" scoped>
  .chats-list {
    .left-chat-item {
      height: 80px;
      border-bottom: 1px solid #E8ECF2;
      display: flex;
      justify-content: flex-start;
      padding: 20px 20px 20px;
      position: relative;
      cursor: pointer;

      &.active,
      &:hover {
        background-color: #F5F7FA;
      }

      &:hover {
        .delete-chats-icon {
          display: block;
        }
        .mute-team-icon{
          display: none;
        }
      }
      .red-star{
        position: relative;
        &::before{
          content: '';
          display: inline-block;
          width: 8px;
          height: 8px;
          background: #F56C6C;
          position: absolute;
          right:0;
          border-radius: 8px;
          top: 0;
        }
      }
      .user-info {
        margin-left: 20px;
        text-align: left;
        width: calc(100% - 60px);

        .user-info-head {
          display: flex;
          justify-content: space-between;

          .user-name {
            font-size: 14px;
            color: #333333;
            margin-bottom: 12px;

            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }

          .last-time {
            color: #91A1B7;
            font-size: 12px;
            white-space:nowrap;
          }
        }

        .message {
          color: #636C78;
          font-size: 12px;
          white-space: nowrap;
          width: calc(100% - 20px);
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .delete-chats-icon,.mute-team-icon {
        color: #F34261;
        position: absolute;
        top: 40px;
        right: 20px;
        display: none;
        cursor: pointer;

      }
      .delete-chats-icon{
        width: 22px;
        height: 22px;
      }
      .mute-team-icon{
        color: #A9b4c6;
         display: block;
      }
    }
    /deep/ .en-title-card {
      .en-title-card-right-container {
        > .right-slot {
          display: flex;
          justify-content: flex-start;
          .search {
            color: #A9B5C6;
            margin: 4px 12px;
            cursor: pointer;
          }
          .line{
            height: 26px;
            margin: 0 18px;
          }
          .add-chat {
            display: flex;
            justify-content: flex-start;
            line-height: 26px;
            cursor: pointer;

            .en-icon {
              color: #3e90fe;
              margin: 4px 6px 4px 0;
            }

            .label {
              font-size: 12px;
              color: #636C78;
            }
          }
        }
      }
    }
    .index-size{
      padding: 20px;
      & /deep/ img{
        width: 90px;
        height: 86px;
      }
    }
    .search-bar{
      width: 130px;
      margin-top: -4px;
    }
  }
</style>
